.modal-title {
  color: var(--primary);
  font-size: 28px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
}

.modal-header {
  justify-content: space-between;
}
