.filter-item {
  width: 100%;
  padding: 0px 10px 0px 5px;
  padding-left: 5px;
  color: var(--primary);
  font-weight: 400;
  /* margin: 2px; */
  cursor: pointer;
  box-sizing: border-box;
  font-size: 16px;
}

.orbit.filter-item {
  justify-content: space-between;
  align-items: center;
}

.orbit.filter-item #capacity {
  font-size: 10px;
  line-height: 15px;
}

.missing-property-container {
  display: flex;
  justify-content: space-between;
}

.missing-property-container .missing-number {
  font-size: 14px;
  font-weight: bold;
  /* color: white; */
  height: 18px;
  min-width: fit-content;
  /* padding: 0px 3px 0px 3px; */
  text-align: center;
  border-radius: 10px;
  /* background-color: var(--secondary); */
}
