.slide {
  min-height: 78vh;
  padding: 7%;
  max-height: 100%;
  color: var(--primary);
  text-align: center;
}

.slide-headline {
  letter-spacing: 0.15px;
  line-height: 97.52px;
  width: 80%;
  font-size: 80px;
}

#headline14 {
  font-size: 36px;
  margin-bottom: 50px;
}

.slide-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  margin-top: 10px;
}

.slide-body {
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
}

.main-description {
  width: 60%;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.sub-description {
  width: 70%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media only screen and (max-width: 999px) {
  .slide {
    padding-top: 14%;
  }

  .slide-headline {
    font-size: 50px;
    line-height: 80px;
  }

  .slide-title {
    font-size: 30px;
  }

  .main-description {
    width: 90%;
    font-size: 18px;
  }

  .sub-description {
    width: 90%;
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .slide-headline {
    margin-top: 20%;
    font-size: 40px;
    font-weight: bold;
  }

  .slide-title {
    font-size: 25px;
    font-weight: bold;
  }

  .main-description {
    font-size: 14px;
  }

  .sub-description {
    font-size: 11px;
  }
  #headline14 {
    font-size: 30px;
    margin-bottom: 60px;
  }
}
