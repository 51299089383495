.orbits-sidebar {
  padding: 30px 15.5px;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px;
  border-color: blueviolet;
}

.group-options {
  width: 100%;
  justify-content: space-between;
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  padding-left: 15px;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
}

.group-options .column {
  justify-content: space-between;
  align-items: center;
  height: 38px;
  width: 51px;
  color: #cbc9cc;
}

.group-options svg {
  cursor: pointer;
}

.group-options .clear-btn {
  height: 24px;
  padding: 0px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #cbc9cc;
}

.group-options .clear-btn svg {
  height: 26px;
  width: 26px;
  cursor: pointer !important;
}

.group-options .clear-btn:active svg {
  color: var(--primary);
}

.group-options.disabled svg {
  cursor: unset;
}

.Collapsible {
  width: 100%;
  box-sizing: border-box;
}

.Collapsible__trigger {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bolder;
  line-height: 22px;
  color: var(--primary);
}

.Collapsible__trigger span {
  cursor: pointer;
}

#arrow-right,
#arrow-down {
  cursor: pointer;
  width: 10px;
  height: 10px;
}

.Collapsible__trigger.is-open #arrow-right,
.Collapsible__trigger.is-closed #arrow-down {
  display: none;
}

.Collapsible__trigger.is-open #arrow-down,
.Collapsible__trigger.is-closed #arrow-right {
  display: unset;
}

.Collapsible__contentInner > div .Collapsible .Collapsible__trigger {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: var(--primary);
}

.Collapsible__contentInner > div .Collapsible .Collapsible__contentInner {
  padding-top: 0px;
}

.Collapsible__contentInner {
  padding-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
}

.sublist-trigger {
  cursor: unset !important;
}

.sublist-trigger svg,
.sublist-trigger span {
  cursor: pointer;
}

.contacts-page .main-content-wrapper {
  padding: 45px 20px 30px 30px;
  box-sizing: border-box;
  overflow-x: auto;
}

.contacts-page .header {
  width: 100%;
  height: 69px;
  justify-content: space-between;
}

.contacts-page .header .actions {
  color: var(--primary);
  width: 10%;
  justify-content: flex-end;
  font-size: 20px;
  position: absolute;
  right: 50px;
}

.contacts-page .header .actions > svg {
  width: 45px;
  height: 45px;
}

.contacts-page .header .actions #menuItem {
  font-weight: bold;
  font-size: 16px;
  color: var(--primary);
}

.contacts-page .actions .menu-trigger {
  cursor: pointer;
  width: 45px;
  height: 45px;
  padding: 0px;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: transparent;
}

.contacts-page .actions .menu-trigger:active {
  background-color: var(--secondary50);
}

.contacts-page .actions .menu-trigger #icon {
  width: 45px;
  height: 45px;
}

.accountLink {
  color: #000000;
  width: 100%;
  text-decoration: none;
}

.add-process-description {
  font-size: 10px;
  color: var(--secondary);
}

.edit-icon {
  position: relative;
  right: 25px;
}

.edit-icon:hover {
  cursor: pointer;
}

.edit-Orbit-name-container {
  display: flex;
  font-size: 16px;
}

.edit-Orbit-name {
  width: 80%;
  height: 18px;
  font-family: "Montserrat";
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 2px solid #d7cfdc;
  /* border-radius: 4px; */
  margin-bottom: 15px;
}

.missing-properties-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
}

.missing-property {
  width: 91%;
}

.orbits {
  display: flex;
  flex-direction: column;
  /* border: 1px solid grey; */
}

.add-list-input:focus {
  font-style: normal;
}

.options-menu {
  border: 1px solid var(--secondary);
  border-radius: 0px 4px 0px 4px;
  list-style-type: none;
  position: absolute;
  z-index: 1px;
  margin-top: 0px;
  padding: 0px;
  background-color: white;
}
.options-menu:empty {
  border: none;
}

.options-menu-item:hover {
  cursor: pointer;
  background-color: var(--warmGray);
}

.adding-input-container {
  display: flex;
  align-items: center;
}

.adding-input-container .adding-input {
  border: none;
  outline: none;
  border-bottom: 2px solid #d7cfdc;
  font-size: 16px;
  font-family: "Montserrat";
  font-style: italic;
  margin-left: 13.5px;
  width: 90px;
  padding: 0;
}

.adding-input-container .adding-input:focus {
  font-style: normal;
}

.group-container {
  display: flex;
  flex-direction: column;
}

.group-container .list-container {
  margin-left: 24px;
}

.group-container .whole-row {
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-container .whole-row .delete-icon {
  width: 12px;
  height: 12px;
}

.group-container .whole-row .delete-icon:hover {
  cursor: pointer;
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pages-container {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  font-size: 12px;
  margin-top: 20px;
  align-self: flex-end;
  /* border: 1px solid red; */
}

.pages-item {
  margin-left: 15px;
}

.pages-item a:-webkit-any-link {
  text-decoration: none;
  color: white;
  /* border: 1px solid red; */
  background-color: var(--secondary);
  border-radius: 50%;
  padding: 5px 10px 5px 10px;
}

.pages-item.pages-active a:-webkit-any-link {
  background-color: var(--secondary50);
}

.pages-disabled {
}

.pages-item a:-webkit-any-link {
  text-decoration: none;
}

.add-contact-card-body {
  width: 45%;
  height: 560px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: white;
  padding: 16px 40px 24px 40px;
  border-radius: 4px;
  border: 1px solid #340f52;
}

.add-contact-card-body .first-section-container {
  border-bottom: 1px solid var(--primary60);
  padding-bottom: 18px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.add-contact-card-body .first-section-container .add-contact-avatar {
  width: 66px;
  height: 66px;
  border-radius: 50%;
}

.add-contact-card-body .first-section-container .add-contact-avatar:hover {
  opacity: 0.7;
  cursor: pointer;
}

.add-contact-card-body .first-section-container .name-orbit-lists {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  width: fit-content;
}

.add-contact-card-body .first-section-container .name-orbit-lists .names {
  display: flex;
  flex-direction: row;
}

.add-contact-card-body .first-section-container .name-orbit-lists .names input {
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat";
  color: #000000;
  border: none;
  outline: none;
  width: fit-content;
  max-width: 100px;
  margin-top: 8px;
}

.add-contact-card-body .first-section-container .name-orbit-lists .orbit-lists {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  align-items: center;
}

.add-contact-card-body .first-section-container .notes-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
  margin-left: 65px;
}

.add-contact-card-body .first-section-container .notes-container .note-input {
  height: 12px;
  font-size: 12px;
  font-family: "Montserrat";
  background-color: var(--warmGray);
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  margin-top: 3px;
}

.add-contact-card-body
  .first-section-container
  .notes-container
  .note-input
  input {
  margin-left: 7px;
  font-size: 12px;
  font-family: "Montserrat";
  font-style: italic;
  background-color: var(--warmGray);
  border: none;
  outline: none;
  width: 80%;
}

.add-contact-card-body
  .first-section-container
  .notes-container
  .note-input
  input:focus {
  font-style: normal;
}

.add-contact-card-body .second-section-container {
  /* border: 1px solid red; */
  border-bottom: 1px solid var(--primary60);
  /* height: 80px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
}

.add-contact-card-body .second-section-container .title {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.add-contact-card-body .second-section-container .details-container {
  display: flex;
  flex-direction: row;
}

.add-contact-card-body
  .second-section-container
  .details-container
  .vertical-line {
  height: 100%;
  border: 0.5px solid var(--primary60);
}

.add-contact-card-body
  .second-section-container
  .details-container
  .personal-container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 16px;
  margin-left: 10px;
  width: 50%;
}

.add-contact-card-body
  .second-section-container
  .details-container
  .personal-container
  .header {
  font-size: 14px;
  font-weight: 400;
  height: fit-content;
}

.add-contact-card-body
  .second-section-container
  .details-container
  .personal-container
  .personal-detail {
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-top: 10px;
}

.add-contact-card-body
  .second-section-container
  .details-container
  .personal-container
  .personal-detail
  input {
  font-size: 14px;
  font-style: italic;
  font-family: "Montserrat";
  margin-left: 10px;
  color: var(--nickel);
  border: none;
  outline: none;
  border-bottom: 2px solid #d7cfdc;
  width: 40%;
}

.add-contact-card-body
  .second-section-container
  .details-container
  .personal-container
  .personal-detail
  input:focus {
  color: #000000;
  font-style: normal;
}

.add-contact-card-body .third-section-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.add-contact-card-body .third-section-container .partner {
  display: flex;
}

.add-contact-card-body .third-section-container .title {
  font-weight: 600;
  font-size: 14px;
  padding-right: 12px;
}

.add-contact-card-body .third-section-container .edit-icon {
  width: 18px;
  height: 18px;
  right: 0px;
}

.add-contact-card-body .third-section-container .partner .x-icon {
  width: 10px;
  height: 10px;
  display: flex;
  align-self: center;
}

.add-contact-card-body .third-section-container .partner .x-icon:hover {
  cursor: pointer;
}

.add-contact-card-body .third-section-container .key-people {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.add-contact-card-body
  .third-section-container
  .key-people
  .addition-key-people {
  display: flex;
  flex-direction: column;
  max-height: 60px;
}

.add-contact-card-body
  .third-section-container
  .key-people
  .list-of-key-people {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.add-contact-card-body
  .third-section-container
  .key-people
  .list-of-key-people
  .x-icon {
  width: 10px;
  height: 10px;
  display: flex;
  align-self: center;
}

.add-contact-card-body
  .third-section-container
  .key-people
  .list-of-key-people
  .x-icon:hover {
  cursor: pointer;
}

.add-contact-card-body
  .third-section-container
  .key-people
  .list-of-key-people::-webkit-scrollbar {
  width: 3px;
}

.add-contact-card-body .third-section-container .key-people .addition-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  padding-bottom: 10px;
}

.add-contact-card-body .third-section-container .key-people .edit-icon {
  /* margin-left: 25px; */
  right: 0;
}

.add-contact-card-body .third-section-container .notes {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
}

.add-contact-card-body .third-section-container .notes .notes-header {
  display: flex;
  justify-content: space-between;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-header
  .edit-icon {
  right: 0px;
}

.add-contact-card-body .third-section-container .notes .notes-content {
  margin-top: 10px;
  width: 100%;
  height: 90px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content::-webkit-scrollbar {
  /* width: 3px; */
  border-radius: 2.5px;
}
.add-contact-card-body
  .third-section-container
  .notes
  .notes-content::-webkit-scrollbar-thumb {
  background-color: #817d85;
}
.add-contact-card-body
  .third-section-container
  .notes
  .notes-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary);
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container {
  display: flex;
  justify-content: space-between;
  min-height: 16px;
  border-radius: 4px;
  background-color: var(--warmGray);
  width: 97%;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .note-and-pin {
  display: flex;
  align-items: center;
  /* width: 70%; */
}
.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .grey-pin-icon {
  width: 15px;
  height: 15px;
  background-image: url("../../assets/pin-grey.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .grey-pin-icon:hover {
  cursor: pointer;
  background-image: url("../../assets/pin-purple.svg");
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .purple-pin-icon {
  width: 15px;
  height: 15px;
  background-image: url("../../assets/pin-purple.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .purple-pin-icon:hover {
  cursor: pointer;
  background-image: url("../../assets/pin-grey.svg");
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .note {
  font-size: 12px;
  font-weight: 400;
  color: var(--primary);
  padding: 0px 10px 0px 10px;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .right-field {
  display: flex;
  align-items: center;
  width: 13%;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .right-field
  .x-icon {
  width: 17px;
  height: 17px;
  margin-left: 10px;
  display: flex;
  align-self: center;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .right-field
  .x-icon:hover {
  cursor: pointer;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .right-field
  .timestamp-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-contact-card-body
  .third-section-container
  .notes
  .notes-content
  .note-container
  .right-field
  .timestamp-container
  .timestamp {
  font-size: 8px;
  font-weight: 400;
  color: var(--primary);
  padding-right: 0px;
}
