.orbit-btn {
  background-color: #817d85;
  color: white;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 700;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit-btn:focus {
  outline: none;
}

.orbit-btn:active {
  box-shadow: 0px 0px 1px gray;
}

.orbit-btn.dark {
  background-color: var(--secondary);
}
.orbit-btn.dark60 {
  background-color: var(--secondary50);
}

.orbit-btn.white {
  background-color: white;
  color: var(--primary);
}
