.signup-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background-color: var(--primary); */
}

.signup-container #logo {
  width: 220px;
  margin: auto;
  margin-left: 2%;
}

.signup-container .logo-container {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.signup-container .title {
  font-size: 36px;
}

.signup-title {
  width: 100%;
  height: 70px;
  justify-content: space-around;
  align-items: center;
}

.google .signup-title {
  margin-top: 50px;
}

.signup-container .subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--primary);
}

.signup-form-container {
  width: 100%;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5%;
}

.signup-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.signup-form > div {
  width: 100%;
  flex-grow: 1;
}

.signup-form .form-column {
  margin: 0px 4%;
  width: 100%;
  height: 100%;
  max-width: 285px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.signup-form label {
  font-size: 12px;
  color: var(--primary);
}

.split-form {
  /* width: 100%; */
  justify-content: space-around;
  align-items: center;
  height: 95%;
}

.split-form .label {
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--primary);
  width: 100%;
  max-width: 454px;
  align-items: flex-start;
}

.split-form .error {
  color: var(--failure);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: right;
}

.split-form .failure-error {
  width: 640px;
  height: 32px;
  background-color: var(--brightRose);
  border-radius: 4px;
  color: var(--warmGray);
  box-sizing: border-box;
}

.split-form .failure-error.hidden {
  visibility: hidden;
}

.card-number {
  width: 100%;
  max-width: 444px;
  border-bottom: 1px solid var(--primary);
  border-radius: 4px 4px 0px 0px;
}

.card-expiry {
  width: 50%;
  max-width: 231px;
  border-right: 1px solid var(--primary);
  border-radius: 0px 0px 0px 4px;
}

.card-cvc {
  width: 50%;
  max-width: 231px;
  border-left: 1px solid var(--primary);
  border-radius: 0px 0px 4px 0px;
}

.country-select {
  width: 100%;
}
.country-select:focus {
  outline: none;
}

.ElementsApp {
  width: 100%;
  height: 100%;
}

.test-class {
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 11px;
  background-color: var(--warmGray);
  color: var(--primary);
  border-radius: 4px;
}

.google-btn {
  position: relative;
  width: 274px;
  height: 66px;
}

.google-btn .hidden-btn,
.google-btn .ui-btn-container {
  position: absolute;
  height: 55px;
}

.hidden-btn {
  z-index: 1;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  top: 13px;
  width: 230px;
  left: 22px;
}

.ui-btn-container {
  width: 274px;
}

.ui-btn .firebaseui-idp-button {
  width: 100%;
  height: 48px;
}
