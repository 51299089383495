.flat-btn button,
.flat-btn a {
  background-color: transparent;
  color: var(--primary);
  border: none;
  font-family: "Montserrat";
  font-size: 18px;
  letter-spacing: 0.2px;
  cursor: pointer;
  text-decoration: none;
  padding: 0px;
}

.flat-btn button:focus,
.flat-btn a:focus,
.flat-btn:focus {
  outline: none;
}

.flat-btn button:disabled,
label.flat-btn.disabled {
  cursor: auto;
  color: var(--primary60);
}
