.contacts-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  color: var(--primary);
}

.contacts-table .header-row th {
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid var(--primary60);
}

.contacts-table th {
  padding: 10px 0px;
}

.contacts-table td {
  padding: 12px 10px 5px 0px;
}

.contacts-table td,
.editable-cell input {
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--primary);
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.editable-cell {
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.editable-cell .editable-input-container {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.editable-cell input {
  height: 30px;
  max-width: 100% !important;
  box-sizing: border-box;
}

.editable-cell input:enabled {
  border-color: var(--primary60);
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-radius: 5px 0px 0px 5px;
  padding: 5px 3px 5px 8px;
  outline: none;
}

.editable-cell input:disabled {
  border: none;
  background-color: transparent;
}

.editable-cell .done-icon {
  /* font-size: 10px; */
  cursor: pointer;
  border-radius: 0px 5px 5px 0px;
  height: 30px;
  border-color: var(--primary60);
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  padding-right: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.done-icon {
  cursor: pointer;
  border-radius: 0px 5px 5px 0px;
  height: 30px;
  border-color: var(--primary60);
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  padding-right: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.orbit-number {
  background-color: var(--secondary);
  color: white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.orbit-number-beyond {
  background-color: white;
  color: var(--secondary);
  border-radius: 50%;
  border: 2px solid var(--secondary);
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.orbit-number-border {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border-color: var(--secondary);
  border-width: 2px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.orbit-number-border.is-full {
  border: 2px solid var(--brightRose);
}

.list-tag {
  height: 20px;
  width: fit-content;
  background-color: var(--secondary);
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-family: "Montserrat";
  box-sizing: border-box;
  padding: 2px 6px;
  margin-right: 3px;
  border: none;
  display: flex;
  align-items: center;
}

.list-tag .delete-icon {
  width: 13px;
  height: 13px;
  background-image: none;
}

.list-tag .delete-icon:hover {
  background-image: url("../../assets/x.svg");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.list-tag.test {
  background-color: white;
  border: none;
  outline: none;
}
.list-tag.test:hover {
  background-color: rgba(52, 15, 82, 0.6);
  cursor: pointer;
}

.list-tag.test:focus {
  background-color: rgba(52, 15, 82, 0.6);
}

.contact-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-color: white;
}

.contacts-table .title {
  justify-content: flex-start;
  align-items: center;
}

.contacts-table .title.selected {
  font-weight: bold;
  color: var(--primary);
}

.sort-icon {
  cursor: pointer;
  margin-left: 4px;
  color: var(--primary60);
}

.selected .sort-icon {
  color: var(--primary);
}

.status-tag {
  height: 16px;
  background-color: var(--duskBlue);
  font-size: 10px;
  border-radius: 3px;
  color: white;
  padding: 2px 6px;
}

.tags-list {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.tags-list::-webkit-scrollbar {
  display: none;
}

.contact-card-body {
  width: 53%;
  height: 475px;
  padding-bottom: 75px;
  border: 1px solid #340f52;
  border-radius: 4px;
  background-color: white;
  position: fixed;
  left: 45%;
  top: 160px;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
}

.contact-card-body .first-section {
  padding: 0px 38px 0px 38px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}
.contact-card-body .first-section .close-icon {
  position: absolute;
  right: 40px;
}
.contact-card-body .first-section .close-icon:hover {
  cursor: pointer;
}

.contact-card-body .first-section .avatar-img {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: var(--warmGray);
}

.contact-card-body .first-section .profile-info {
  margin-left: 8px;
  padding: 5px 0px 20px 0px;
  display: flex;
  width: 50%;
  border-bottom: 2px solid var(--warmGray);
}

.contact-card-body .first-section .profile-info .name-orbit-tags-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 18px;
}

.contact-card-body
  .profile-info
  .name-orbit-tags-container
  .orbit-tags-container {
  display: flex;
  align-items: center;
}

.contact-card-body
  .first-section
  .profile-info
  .name-orbit-tags-container
  .full-name {
  font-size: 18px;
  font-weight: 600;
}

.contact-card-body .first-section .notes-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 20px;
  margin-top: 15px;
  border-bottom: 2px solid var(--warmGray);
}

.contact-card-body .first-section .notes-section .last-contacted {
  font-size: 10px;
}

.contact-card-body .first-section .notes-section .note-input {
  height: 12px;
  font-size: 12px;
  font-family: "Montserrat";
  background-color: var(--warmGray);
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  margin-top: 3px;
}

.contact-card-body .first-section .notes-section .note-input input {
  margin-left: 7px;
  font-size: 12px;
  font-family: "Montserrat";
  font-style: italic;
  background-color: var(--warmGray);
  border: none;
  outline: none;
  width: 80%;
}
.contact-card-body .first-section .notes-section .note-input input:focus {
  font-style: normal;
}

.contact-card-body .first-section .profile-info .line {
  margin-top: 5px;
  border: 1px solid var(--warmGray);
}

.contact-card-body .similar-section {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
}

.contact-card-body .second-section {
  border-bottom: 2px solid var(--warmGray);
  padding: 15px 0px 15px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-card-body .second-section .tags-list {
  margin-left: 10px;
  width: 60%;
}

.contact-card-body .third-section {
  padding: 20px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid var(--warmGray);
}

.contact-card-body .third-section .title {
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat";
}

.contact-card-body .third-section .details {
  height: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.contact-card-body .third-section .details .example {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
}

/* .contact-card-body .third-section .details .example .share-icon {
  margin-left: 225px;
} */

.contact-card-body .third-section .details .example.left {
  padding-right: 25px;
}
.contact-card-body .third-section .details .example.right {
  padding-left: 25px;
  border-left: 2px solid var(--warmGray);
}

.contact-card-body .third-section .details .example .example-details {
  padding-left: 12px;
  padding-top: 10px;
  max-width: 320px;
  /* border: 1px solid red; */
}
.contact-card-body .third-section .details .example .example-details .detail {
  margin-left: 10px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.contact-card-body
  .third-section
  .details
  .example
  .example-details
  .detail::-webkit-scrollbar {
  display: none;
}

.contact-card-body .fourth-section .title {
  font-weight: 600;
  font-size: 14px;
  padding-right: 12px;
}

.contact-card-body .fourth-section .contacts-suggestions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-card-body .fourth-section .partner {
  margin-left: 24px;
  margin-top: 15px;
  display: flex;
}

.contact-card-body .fourth-section .partner .loading-icon {
  margin-left: 5px;
}

.contact-card-body .fourth-section .edit-icon {
  width: 18px;
  height: 18px;
  right: 0px;
}

.contact-card-body .fourth-section .partner .x-icon {
  width: 10px;
  height: 10px;
  display: flex;
  align-self: center;
}

.contact-card-body .fourth-section .partner .x-icon:hover {
  cursor: pointer;
}

.contact-card-body .fourth-section .key-people {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.contact-card-body .fourth-section .key-people .addition-key-people {
  display: flex;
  flex-direction: column;
  max-height: 60px;
}

.contact-card-body .fourth-section .key-people .list-of-key-people {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.contact-card-body .fourth-section .key-people .list-of-key-people .x-icon {
  width: 10px;
  height: 10px;
  display: flex;
  align-self: center;
}

.contact-card-body
  .fourth-section
  .key-people
  .list-of-key-people
  .x-icon:hover {
  cursor: pointer;
}

.contact-card-body
  .fourth-section
  .key-people
  .list-of-key-people::-webkit-scrollbar {
  width: 3px;
}

.contact-card-body .fourth-section .key-people .addition-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  padding-bottom: 10px;
}

.contact-card-body .fourth-section .key-people .edit-icon {
  /* margin-left: 25px; */
  right: 0;
}

.add-person {
  font-size: 14px;
  font-style: italic;
  font-family: "Montserrat";
  border: none;
}
.add-person:focus {
  border: none;
  outline: none;
  font-style: normal;
}
.person-name {
  font-weight: 400;
  font-size: 14px;
  margin-left: 5px;
}

.options-menu.contacts {
  left: 200px;
}

.contact-card-body .fourth-section .notes {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
}

.contact-card-body .fourth-section .notes .notes-header {
  display: flex;
  justify-content: space-between;
}

.contact-card-body .fourth-section .notes .notes-header .edit-icon {
  right: 0px;
}

.contact-card-body .fourth-section .notes .notes-content {
  margin-top: 10px;
  width: 100%;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.contact-card-body .fourth-section .notes .notes-content::-webkit-scrollbar {
  /* width: 3px; */
  border-radius: 2.5px;
}
.contact-card-body
  .fourth-section
  .notes
  .notes-content::-webkit-scrollbar-thumb {
  background-color: #817d85;
}
.contact-card-body
  .fourth-section
  .notes
  .notes-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary);
}

.contact-card-body .fourth-section .notes .notes-content .note-container {
  display: flex;
  justify-content: space-between;
  min-height: 16px;
  border-radius: 4px;
  background-color: var(--warmGray);
  width: 97%;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .note-and-pin {
  display: flex;
  align-items: center;
  /* width: 70%; */
}
.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .grey-pin-icon {
  width: 15px;
  height: 15px;
  background-image: url("../../assets/pin-grey.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .grey-pin-icon:hover {
  cursor: pointer;
  background-image: url("../../assets/pin-purple.svg");
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .purple-pin-icon {
  width: 15px;
  height: 15px;
  background-image: url("../../assets/pin-purple.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .purple-pin-icon:hover {
  cursor: pointer;
  background-image: url("../../assets/pin-grey.svg");
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .note-and-pin
  .note {
  font-size: 12px;
  font-weight: 400;
  color: var(--primary);
  padding: 0px 10px 0px 10px;
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .right-field {
  display: flex;
  align-items: center;
  width: 13%;
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .right-field
  .x-icon {
  width: 17px;
  height: 17px;
  margin-left: 10px;
  display: flex;
  align-self: center;
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .right-field
  .x-icon:hover {
  cursor: pointer;
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .right-field
  .timestamp-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contact-card-body
  .fourth-section
  .notes
  .notes-content
  .note-container
  .right-field
  .timestamp-container
  .timestamp {
  font-size: 8px;
  font-weight: 400;
  color: var(--primary);
  padding-right: 0px;
}
