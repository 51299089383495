::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

.header {
  display: flex;
}

.header .app-logo {
  padding: 30px 0px 0px 16px;
}

.header .app-logo:hover {
  cursor: pointer;
}
.header .headline {
  display: inline-block;
  margin-left: 190px;
  margin-top: 70px;
  font-size: 24px;
  color: black;
}

.break-line {
  color: var(--primary);
  width: 96%;
  border: 0.5px solid black;
  margin-bottom: 40px;
}

.body {
  display: flex;
}

.body .title {
  margin-top: 5px;
  font-size: 18px;
  color: var(--primary);
}

.options-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  /* border: 1px solid red; */
}

.options-container .description {
  padding-bottom: 14px;
  font-size: 8px;
  font-style: italic;
  color: #817d85;
  margin-left: 20px;
  width: 240px;
}

.options-container .options {
  font-size: 14px;
  color: #817d85;
}

.options-container .options-field {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.options-container .options-value {
  margin-left: 20px;
  font-size: 14px;
}

.options-container input {
  width: 330px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #817d85;
  border-radius: 4px;
  font-size: 20px;
  font-family: "Montserrat";
}

.options-container.password {
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  /* border: 1px solid red; */
}

.personal-container {
  display: flex;
  padding: 0px 185px 0px 205px;
}

.personal-container .personal-title {
  font-size: 18px;
  /* padding-right: 11.5%; */
}

.personal-container .personal-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 46%;
}

.personal-container .personal-data .personal-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}

.personal-container .personal-data .personal-field .edit-icon {
  right: 0px;
}

.personal-container .personal-data .personal-field .title-container {
  display: flex;
  justify-content: flex-end;
  width: 170px;
}

.personal-container
  .personal-data
  .personal-field
  .title-container
  .personal-field-title {
  font-size: 14px;
  color: #817d85;
}

.personal-container .personal-data .personal-field .personal-field-data {
  font-size: 14px;
  color: var(--primary);
  margin-left: 20px;
  width: 300px;
}

.personal-container .personal-data .personal-field input {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat";
  font-style: normal;
  margin-left: 20px;
  border: none;
  outline: none;
  border-bottom: 2px solid #d7cfdc;
  height: 14px;
}

.personal-container .personal-data .personal-field .placeholder-style {
  font-size: 14px;
  font-style: italic;
  color: var(--moon);
  margin-left: 20px;
}

.personal-container .personal-data .personal-field .description {
  font-size: 8px;
  font-style: italic;
  color: var(--moon);
  margin-left: 20px;
  width: 300px;
}

.personal-container .profile-picture-container {
  margin-left: 200px;
  height: 100%;
}

.change-password-container {
  display: flex;
  padding: 0px 431px 0px 163px;
}

.change-password-container .change-password-title {
  font-size: 18px;
  width: 21%;
}

.change-password-container .change-password-data {
  display: flex;
  flex-direction: column;
  width: 79%;
  margin-top: -7px;
}

.change-password-container .change-password-data .change-password-data-field {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 8px;
}

.change-password-container .change-password-data .error-message {
  color: var(--failure);
  font-size: 12px;
  margin-left: 27%;
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .change-password-data-field-title {
  font-size: 14px;
  color: #817d85;
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .description-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.change-password-container .change-password-data .description-message {
  font-size: 10px;
  color: var(--primary);
  margin-left: 190px;
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .description-column
  input {
  width: 100%;
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .password-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 575px;
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .password-container
  .warning-message {
  font-size: 10px;
  color: var(--brightRose);
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .password-container
  .check-message {
  font-size: 10px;
  color: var(--primary);
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .password-container
  .password-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #817d85;
  border-radius: 4px;
  height: 32px;
  padding-left: 5px;
  margin-left: 20px;
  width: 330px;
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .password-container
  .password-input
  input {
  border: none;
  outline: none;
  height: 30px;
  width: 100%;
  font-size: 14px;
  font-family: "Montserrat";
}

.change-password-container
  .change-password-data
  .change-password-data-field
  .password-container
  .password-input
  input.invisible {
  font-size: 40px;
  font-family: "Montserrat";
}
