.privacy-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  line-height: 23px;
}

.privacy-container #logo {
  width: 220px;
  margin: auto;
  margin-left: 45px;
}

.privacy-container .logo-container {
  width: 100%;
  height: 11%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  position: fixed;
  z-index: 2;
}

.secondary {
  color: var(--secondary);
}

.primary {
  color: var(--primary);
}

.privacy-container p {
  font-family: "Montserrat";
  color: var(--primary);
  font-size: 17px;
}

.privacy-container h4 {
  font-family: "Montserrat";
  text-decoration: underline;
  font-weight: bold;
}
