.raised-btn button,
.raised-btn a,
label.raised-btn {
  background-color: var(--warmGray);
  color: var(--primary);
  text-align: center;
  border-radius: 5px;
  font-family: "Montserrat";
  height: 50px;
  width: 100%;
  line-height: 25px;
  font-size: medium;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-weight: 600;
}

.raised-btn button:focus,
.raised-btn a:focus,
label.raised-btn:focus {
  outline: none;
}

.raised-btn:focus {
  outline: none;
}

.raised-btn button:active {
  box-shadow: 0px 0px 1px gray;
}

.raised-btn.dark button,
.raised-btn.dark a,
.raised-btn.dark .icon,
label.raised-btn.dark {
  background-color: var(--secondary);
  color: var(--warmGray);
}

/* .raised-btn:hover {
} */

.raised-btn.with-icon button,
label.raised-btn.with-icon {
  width: 80%;
  border-radius: 5px 0px 0px 5px;
}

.raised-btn .icon {
  background-color: var(--warmGray);
  color: var(--primary);
  width: 20%;
  font-size: 17px;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

.raised-btn.dark .icon {
  color: var(--secondary);
  color: var(--warmGray);
}

.raised-btn button:disabled,
label.raised-btn.disabled {
  cursor: auto;
  background-color: var(--primary60);
  color: var(--warmGray);
}

.raised-btn.dark button:disabled,
label.raised-btn.dark.disabled {
  background-color: var(--secondary50);
  color: var(--warmGray);
}
