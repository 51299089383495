.input-container {
  height: 60px;
  justify-content: flex-start;
}

.input-field {
  color: var(--primary);
}

.input-field input {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px;
  padding: 5px 16px;
  font-size: 17px;
  color: var(--primary60) !important;
  background-color: var(--warmGray);
}

.input-field input::placeholder {
  opacity: 0.7;
}

.input-field input:focus {
  outline: none;
}

.input-field.opaque input {
  border-radius: 0px !important;
  border-bottom: 2px solid var(--primary);
  background: transparent;
}

.input-field.opaque input::placeholder {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.input-field.with-icon input {
  max-width: 80%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input-field.with-leading input {
  max-width: 80%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-field .icon,
.input-field .leadingIcon {
  height: 50px;
  width: 20%;
  background-color: var(--warmGray);
  color: var(--primary60);
}

.input-field .icon {
  border-radius: 0px 5px 5px 0px;
}

.input-field .leadingIcon {
  border-radius: 5px 0px 0px 5px;
}

.input-field.opaque .icon,
.input-field.opaque .leadingIcon {
  height: 50px;
  background-color: transparent;
  border-radius: 0px;
  color: var(--primary60);
  border-bottom: 2px solid var(--primary);
  margin-bottom: 2px;
  color: var(--primary60);
}

.input-field input:invalid,
.input-field.invalid input {
  border: 1px solid var(--brightRose);
  color: var(--brightRose) !important;
}

.input-field.with-icon input:invalid,
.input-field.input-field.with-icon.invalid .input-field input {
  border-right: none;
}

.input-field input:invalid ~ .icon,
.input-field.invalid input ~ .icon {
  border: 1px solid var(--brightRose);
  border-left: none;
  height: 48px;
}

.input-field input:invalid ~ .leadingIcon,
.input-field.invalid input ~ .leadingIcon {
  border: 1px solid var(--brightRose);
  border-right: none;
  height: 48px;
}

.input-error {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: var(--brightRose);
  display: none;
}

.input-field.invalid ~ .input-error {
  display: block;
}

.input-hint {
  font-size: 10px;
  color: var(--primary);
}

.input-field.invalid ~ .input-hint {
  color: var(--brightRose);
}

.input-clear {
  color: var(--primary);
  cursor: pointer;
}
