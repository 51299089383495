.landing-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 2%;
}

.landing-container .nav-bar {
  width: 100%;
  height: 15%;
  justify-content: space-between;
}

#loginBtn_2 {
  display: none;
  max-width: 285px;
  margin-left: 10px;
}

.landing-container #logo {
  width: 220px;
  margin: auto;
  margin-left: 0px;
}

.landing-content {
  flex-grow: 1;
  width: 50%;
  align-self: flex-start;
  padding: 50px 0px;
}

.landing-content h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 400;
  text-align: left;
  color: var(--primary);
}

.landing-content p {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: var(--primary);
}

.landing-footer {
  height: 10%;
  width: 100%;
  align-items: flex-start;
}

@media (max-width: 999px) {
  .nav-bar #loginBtn_1 {
    display: none;
  }
  #loginBtn_2 {
    display: flex;
  }

  .landing-content {
    width: 100%;
  }
}
