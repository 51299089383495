.login-header {
  width: 100%;
  justify-content: flex-end;
  font-size: 18px;
  line-height: 22px;
  color: var(--primary);
  height: 160px;
  padding: 50px;
  box-sizing: border-box;
}

.login-container {
  flex-grow: 1;
  max-width: 285px;
  margin: auto;
  justify-content: center;
}

.login-form {
  justify-content: flex-start;
}

#login-logo {
  width: 240px;
  margin: 40px 0px;
}

.login-form label {
  font-size: 12px;
  color: var(--primary);
}

.login-footer {
  justify-self: flex-end;
  position: fixed;
  bottom: 7%;
}

.reset-form {
  justify-content: center;
  align-items: center;
  min-width: 535px;
}

.reset-form .reset-form-label {
  font-size: 18px;
}

.reset-form .input-part {
  width: 70%;
  margin-top: 30px;
}

@media only screen and (max-width: 999px) {
  .login-header {
    font-size: medium;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 900px) {
  .login-header {
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 400px) {
  .login-header {
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
  }
}
