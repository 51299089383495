:root {
  --primary: rgba(45, 38, 51, 1); /* purple gray*/
  --primary60: rgba(45, 38, 51, 0.6); /* purple gray at 0.6 opacity */
  --secondary: rgba(52, 15, 82, 1); /* orbit purple */
  --secondary50: rgba(52, 15, 82, 0.5); /* orbit purple at 0.5 opacity */
  --warmGray: #f2f0f0;
  --tealBlue: "#0B3C49";
  --duskBlue: #587c9c;
  --duskBlue_25: #587c9c25;
  --burntRose: #a2666f;
  --burntRose_25: #a2666f25;
  --brightRose: #d85f71;
  --snow: #f4f4f4;
  --nickel: #666666;
  --coal: #333333;
  --moon: #999999;
  --silver: #cccccc;
  --success: #2ecc71;
  --warning: #f1c40f;
  --failure: #e74c3c;
  --failure50: #e74c3c50;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.row {
  display: flex;
  flex-direction: row;
}

.fixed-row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.scrollable {
  overflow: visible;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer-wrapper {
  height: 100vh;
}

.outer-wrapper:before {
  content: "";
  height: 100%;
  float: left;
}

.inner-wrapper {
  position: relative;
  height: 100vh;
}

.inner-wrapper:after {
  content: "";
  clear: both;
  display: block;
}

.inner-wrapper .main-content-wrapper {
  width: 84%;
  /* width: 1300px; */
}

.fixed-left-side {
  position: fixed;
  overflow-y: scroll;
}

.fixed-left-side::-webkit-scrollbar {
  width: 10px;
  width: 5px;
}

/* Track */
.fixed-left-side::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.fixed-left-side::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 2.5px;
}

.main-content-wrapper {
  float: right;
  overflow-x: scroll;
}

.max-size {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 999px) {
  .row {
    display: flex;
    flex-direction: column;
  }

  .fixed-left-side {
    /* width: 100% !important ; */
    height: 11%;
    background-color: transparent;
  }

  .main-content-wrapper {
    padding-top: 12%;
    /* width: 100% !important; */
  }
}
