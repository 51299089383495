.sidebar {
  max-width: 100%;
  height: 100vh;
  padding-left: 15%;
  display: flex;
  flex-flow: column;
}

.sidebar #logo {
  max-width: 100%;
  height: 15%;
  align-items: center;
  margin: 40px 0px 20px;
}

.sidebar #logo img {
  max-width: 100%;
  width: 100%;
}

.sidebar #chart {
  max-height: 100%;
  height: 100%;
  max-width: 90%;
  padding-left: 10%;
}

.sidebar-element {
  cursor: pointer;
}

.sidebar-element.selected {
  font-weight: bolder;
}

.sidebar-element:hover {
  text-decoration: underline;
}

.sidebar-element {
  color: var(--primary);
}

@media only screen and (max-height: 1500px) and (min-height: 1000px) {
  .sidebar #logo {
    height: 10%;
  }
}

@media only screen and (min-height: 1501px) and (min-height: 1800px) {
  .sidebar #logo {
    height: 5%;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
  .sidebar #logo {
    height: 10%;
  }
}
@media only screen and (max-width: 999px) {
  .sidebar #logo img {
    width: 180px;
  }

  .sidebar {
    align-items: flex-start;
    padding-left: 20px;
  }

  .sidebar #chart {
    display: none !important;
  }
}
