.orbit-avatar-container {
  /* padding-bottom: 30px; */
  margin-left: 14.5px;
}

.orbit-avatar-container:hover {
  cursor: pointer;
}

.orbit-avatar-container .logo-img {
  max-height: 30px;
}

.o-shape {
  content: " ";
  border: solid var(--secondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 4px;
}

.o-shape > .o-shape {
  margin: 0 auto;
}

.orbit-avatar-container .avatar-img {
  border-radius: 50px;
}
